import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './DocumentPage.module.css';
import Footer from './components/Footer';
import Header from './components/Header';
import NavigationMenu from './components/NavigationMenu';
import UpButton from './components/UpButton';
const TariffsPage = () => {
    useEffect(() => {
        document.title = "Список тарифов и порядок совершения платежей на Сервисе «Botique»";
    }, [])
    const contHref = useRef();
    return (
        <>
            <Header />
            <NavigationMenu />
            <div className={styles.licensePage} ref={contHref}>
                <UpButton href={contHref} />
                <h1 className={styles.centeredHeader}>Список тарифов и порядок совершения платежей на Сервисе «Botique»</h1>
                <h1 className={styles.centeredHeader}>о предоставлении права использования Сервиса «Botique» на условиях простой (неисключительной) лицензии</h1>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>Российская Федерация, город Самара</p>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>Дата размещения: 09 января 2024 года</p>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>Дата вступления в силу: 09 января 2024 года</p>

                <h1 className={styles.centeredHeader}>Термины и определения:</h1>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>В рамках настоящего Договора Стороны договорились о единообразном толковании следующих терминов и понятий:</p>
                <ul className={styles.paragraph}>
                    <li><strong className={styles.boldText}>Использование Сервиса</strong> – действия Продавца с Сервисом, заключающиеся в направлении запросов на изменение внешнего вида Магазина, наполнение и редактирование Контента Магазина, активации функциональности Сервиса в части получения и обработки заказов, формирования доступной аналитики, запуска рассылок.</li>
                    <li><strong className={styles.boldText}>Продавец</strong> - юридическое лицо и индивидуальный предприниматель, действующие в лице своих уполномоченных представителей, принявшие оферту на заключение Договора, в порядке предусмотренном в настоящем документе.</li>
                    <li><strong className={styles.boldText}>Пользователь</strong> – полностью дееспособное физическое лицо, использующее Магазин Продавца для приобретения товаров и/или услуг Продавца.</li>
                    <li><strong className={styles.boldText}>Сервис «Botique»/Сервис</strong> – программное обеспечение «Botique», размещенное на сайте в сети Интернет по адресу <Link to="https://thebotique.ru">https://thebotique.ru</Link>, предназначенное для создания и управления Магазинами в мессенджере Telegram.</li>
                    <li>Под Сервисом также понимаются все его обновления и новые версии, которые Лицензиар может, но не обязан, предоставлять Продавцу.</li>
                    <li><strong className={styles.boldText}>Сервис зарегистрирован как программа для ЭВМ «Автоматизированная платформа для создания интернет-магазинов «Botique»» в Государственном реестре программ для ЭВМ (Свидетельство № 2024661306 от «16» мая 2024 г.).</strong></li>
                    <li><strong className={styles.boldText}>Стороны</strong> – Лицензиар и Продавец, упоминаемые совместно.</li>
                    <li><strong className={styles.boldText}>Магазин</strong> – средство обмена электронными сообщениями (чат) в мессенджере Telegram, реализованное как веб-приложение, создаваемое Продавцом в автоматизированном режиме посредством использования Сервиса в целях осуществления Продавцом продажи товаров и/или оказания услуг покупателям.</li>
                    <li><strong className={styles.boldText}>Тариф</strong> – стоимость лицензионного вознаграждения и определенного набора функций в рамках функциональности Сервиса, доступный Продавцу после принятия Соглашения и подключения выбранного Продавцом Тарифа.</li>
                    <li><strong className={styles.boldText}>Контент</strong> – фотографии, видео и текстовые описания товаров и услуг, товарные знаки, логотипы и иные средства индивидуализации Продавца.</li>
                </ul>
                <h1 className={styles.centeredHeader}>1. Описание, стоимость и сроки действия Тарифов</h1>
                <ul className={styles.paragraph}>
                    <p>1.1. Тариф «Пробный», в который включено следующее:</p>
                    <p>- Создание Продавцом одного Магазина путем использования Сервиса;</p>
                    <p>- Создание и заполнения контентом по 4 товарам Продавца;</p>
                    <p>- Применение одного шаблона дизайна Магазина Продавца;</p>
                    <p>- Предоставление Продавцу доступа к 1 полученному через Магазин заказу от Покупателей;</p>
                    <p>- Базовая техническая поддержка с 11:00 до 17:00, обращения отправляются на почту support-thebotique@thebotique.ru .</p>
                    <p>Стоимость Тарифа «Пробный» составляет 0 (ноль) рублей 00 копеек. Срок - не ограничен.</p>
                    <p>1.2. Тариф «Стандарт», в который включено следующее:</p>
                    <p>- Создание Продавцом одного Магазина путем использования Сервиса;</p>
                    <p>- Создание и заполнения контентом до 200 товаров Продавца;</p>
                    <p>- Выбор и применение любого доступного шаблона дизайна Магазина Продавца;</p>
                    <p>- Предоставление Продавцу доступа ко всем полученным через Магазин заказам от Покупателей;</p>
                    <p>- Подключение автоматизированной онлайн-оплаты путем использования сервиса;</p>
                    <p>- Базовая круглосуточная техническая поддержка, обращения отправляются на почту support-thebotique@thebotique.ru .</p>
                    <p>Стоимость Тарифа «Стандарт» составляет 1999 (одна тысяча Девятьсот Девяносто Девять) рублей 00 копеек. Срок – 30 дней с даты оплаты Тарифа «Стандарт».</p>
                    <p>1.3. Тариф «Индивидуальный», в который включено следующее:</p>
                    <p>- Создание Продавцом двух Магазинов путем использования Сервиса;</p>
                    <p>- Создание и заполнения контентом до 200 товаров Продавца;</p>
                    <p>- Выбор и применение любого доступного шаблона дизайна Магазина Продавца;</p>
                    <p>- Разработка индвивидуального дизайна Магазина по запросу Продавца;</p>
                    <p>- Предоставление Продавцу доступа ко всем полученным через Магазин заказам от Покупателей;</p>
                    <p>- Продвинутая круглосуточная техническая поддержка, которая включает в себя сопровождение на всех этапах взаимодействия с сервисом, обращения отправляются на почту support-thebotique@thebotique.ru .</p>
                    <p>Стоимость Тарифа «Индивидуальный» рассчитывается индвивидуально. Срок – 30 дней с даты оплаты Тарифа «Индивидуальный».</p>
                    <p><strong className={styles.boldText}>1.4.	Для подключения выбранного Тарифа Продавцу необходимо прислать Заявку по форме, определенной в Лицензионном договоре Сервиса, размещенному по адресу: <Link to={"https://docs.thebotique.ru/license-agreement"}>https://docs.thebotique.ru/license-agreement</Link> на электронную почту contact@thebotique.ru .</strong></p>
                    <p>1.5.	На основании полученной заявки Продавцу на электронную почту, указанную в заявке, отправляется счет на оплату выбранного Тарифа.</p>
                    <p>1.6.	Оплата производится Продавцом по реквизитам, указанным в счете. Расчеты осуществляются в российских рублях, исполнением обязательств Продавца по оплате считается дата зачисления денежных средств на корреспондентский счет Лицензиара.</p>
                    <p>1.7.	В течение 5 (пяти) рабочих дней с даты предоставления Продавцу фактического доступа к Сервису на электронную почту, указанную в Заявлении, отправляется односторонний Акт о предоставлении доступа к Сервису.</p>
                    <p className={styles.centeredParagraph}><strong className={styles.centeredParagraph}>1.8. Автопродление для Продавца-самозанятого гражданина</strong></p>
                    <p>1.8.1. Для Продавца - самозанятого гражданина подключение и оплата выбранного Тарифа по умолчанию осуществляется на условиях регулярного внесения платы за выбранный Тариф и возобновления Тарифа на определенный таким Тарифом срок доступа. Регулярное внесение платы осуществляется путем списания денежных средств с банковской карты Продавца, с которой была произведена первая оплата Тарифа. Продавец – самозанятый гражданин поручает Сервису по окончанию каждого срока Лицензии от имени Продавца – самозанятого гражданина составлять распоряжение о списании денежных средств в размере стоимости выбранного Тарифа с банковской карты Продавца – самозанятого гражданина, с которой была произведена первая оплата Лицензии, в пользу Лицензиара, а также направлять распоряжение в банк-эмитент Продавца через банк-эквайер (далее – «Автопродление»).</p>
                    <p>1.8.2. После получения информации о факте оплаты, Продавцу – самозанятому гражданину отправляется электронный кассовый чек на адрес электронной почты, указанный Продавцом на странице оплаты.</p>
                    <p>1.8.3. Продавцу-самозанятому гражданину не позднее, чем за 1 (один) день до Автопродления на электронную почу, указанную Продавцом в Сервисе, приходит уведомление о предстоящем списании. Продавец – самозанятый гражданин может отменить Автопродление Лицензии путем самостоятельной отмены в аккаунте Продавца на Сервисе.</p>
                    <p>1.8.4. При отказе от Автопродления, Продавцу – самозанятому гражданину не возвращаются уже уплаченные им денежные средства, при этом Тариф продолжает действовать на оплаченный период, после чего Автопродление Лицензии отменяется. В случае отсутствия достаточной суммы денежных средств на банковской карте Продавца – самозанятого гражданина, с которой была произведена первая оплата Тарифа, доступ Продавца – самозанятого гражданина к Сервису прекращается с даты начала неоплаченного периода.</p>
                    <p className={styles.centeredParagraph}><strong className={styles.centeredParagraph}>1.9. Отказ от использования Сервиса</strong></p>
                    <p>1.9.1. Продавец вправе в любой момент отказаться от использования Сервиса или приостановить использования Сервиса. При этом Продавец соглашается с тем, что действие Тарифа не прекращается в течение оплаченного Продавцом периода, в течение которого он фактически не пользовался своими правами, и все оплаты, полученные от Продавца возврату не подлежат.</p>
                </ul>

                <p className={styles.centeredParagraph}>По всем вопросам, возникающим в связи с Тарифами и порядком совершения платежей следует обратиться по электронной почте contact@thebotique.ru .</p>
            </div >
            <Footer />
        </>
    );
};

export default TariffsPage;
