import React from 'react';
import GetDocumentationSection from '../components/GetDocumentationSection';

const GetProductsList = () => {

    const getEndpoint = "/api/v1/ext/items";
    const getMethod = "GET";
    const getDescription = "Получение списка товаров.";

    const queryParams = [
        { name: 'token', type: 'string', required: true, description: 'API токен.' }
    ];

    return (
        <GetDocumentationSection
            endpoint={getEndpoint}
            method={getMethod}
            description={getDescription}
            queryParams={queryParams}
        />
    );
};

export default GetProductsList;
