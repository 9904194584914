import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import APIdocs from './APIdocs';
import CategoriesPage from './CategoriesPage';
import CharacteristicsPage from './CharacteristicsPage';
import LicenseAgreement from './LicenseAgreement';
import Main from './MainPage';
import NotFound from './NotFound';
import PrivacyPolicy from './PrivacyPolicy';
import PrivacyPolicyAgreement from './PrivacyPolicyAgreement';
import PrivacyPolicyAgreementUser from './PrivacyPolicyAgreementUser';
import ReturnRules from './ReturnRules';
import TariffsPage from './TariffsPage';
import TermsOfUse from './TermsOfUse';

function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="/" element={<Main />} />
                <Route path="/license-agreement" element={<LicenseAgreement />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/return-policy" element={<ReturnRules />} />
                <Route path="/privacy-policy-seller-agreement" element={<PrivacyPolicyAgreement />} />
                <Route path="/privacy-policy-agreement" element={<PrivacyPolicyAgreementUser />} />
                <Route path="/about-tariffs" element={<TariffsPage />} />
                <Route path="/about-api" element={<APIdocs />} />
                <Route path="/about-categories" element={<CategoriesPage />} />
                <Route path="/about-characteristics" element={<CharacteristicsPage />} />
            </Routes>
        </Router>
    );
}

export default AppRouter;


