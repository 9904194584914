import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './DocumentPage.module.css';
import Footer from './components/Footer';
import Header from './components/Header';
import NavigationMenu from './components/NavigationMenu';
import UpButton from './components/UpButton';
const TermsOfUse = () => {
    useEffect(() => {
        document.title = "Условия использования сервиса";
    }, [])
    const contRef = useRef();
    return (
        <>
            <Header />
            <NavigationMenu />
            <div className={styles.licensePage} ref={contRef}>
                <UpButton href={contRef} />
                <h1 className={styles.centeredHeader}>Правила использования Сервиса «Botique»</h1>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>Российская Федерация, город Самара</p>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>Дата размещения: 23 ноября 2023 года</p>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>Дата вступления в силу: 4 декабря 2023 года</p>

                <h1 className={styles.centeredHeader}>Термины и определения:</h1>
                <ul className={styles.paragraph}>
                    <p><strong className={styles.boldText}>Использование Сервиса</strong> – действия Продавца с Сервисом, заключающиеся в направлении запросов на изменение внешнего вида Магазина, наполнение и редактирование Контента Магазина, активации функциональности Сервиса в части формирования доступной аналитики, запуска рассылок.</p>
                    <p><strong className={styles.boldText}>Продавец</strong> - юридическое лицо и индивидуальный предприниматель, действующие в лице своих уполномоченных представителей, принявшие оферту на заключение Договора, в порядке предусмотренном в настоящем документе.</p>
                    <p><strong className={styles.boldText}>Пользователь</strong> – полностью дееспособное физическое лицо, использующее Магазин Продавца для приобретения товаров и/или услуг Продавца.</p>
                    <p><strong className={styles.boldText}>Сервис «Botique»/Сервис</strong> – программное обеспечение «Botique», размещенное на сайте в сети Интернет по адресу thebotique.ru предназначенное для создания и управления Магазинами в мессенджере Telegram.</p>
                    <p><strong className={styles.boldText}>Магазин</strong> - средство обмена электронными сообщениями (чат) в мессенджере Telegram, создаваемый Продавцом в автоматизированном режиме посредством использования Сервиса в целях осуществления Продавцом продажи товаров и/или оказания услуг Пользователям.</p>
                </ul>
                <h1 className={styles.centeredHeader}>1. Общие положения</h1>
                <ul className={styles.paragraph}>
                    <p>1.1. Пользователь использует Сервис «Botique» на условиях, изложенных в настоящем документе.</p>
                    <p>1.2. Использование Сервиса «Botique» регулируется настоящими Правилами использования Сервиса «Botique», Лицензионным договором о предоставлении права использования Сервиса «Botique» на условиях простой (неисключительной) лицензии и Политикой обработки персональных данных. Правила использования Сервиса «Botique» (далее - Правила) могут быть изменены и дополнены без уведомления Пользователей, и вступают в силу с момента публикации адресу: <Link to="https://docs.thebotique.ru/terms-of-use">https://docs.thebotique.ru/terms-of-use</Link>.</p>
                    <p>1.3. Регистрируясь в Сервисе или используя Сервис любым способом, вы принимаете настоящие Правила (от вашего имени или от имени юридического лица или индивидуального предпринимателя, интересы которого вы представляете). Положения настоящих Правил остаются в силе все время, пока вы используете Сервис. Регистрируясь в Сервисе или используя Сервис любым способом, вы заявляете и гарантируете, что вы имеете право и полномочия принять настоящие Правила (от своего имени или от имени юридического лица или индивидуального предпринимателя, интересы которого вы представляете).</p>

                </ul>
                <h1 className={styles.centeredHeader}>2. Предоставляемый Сервис</h1>
                <ul className={styles.paragraph}>
                    <p>2.1. Продавцу  предоставляется доступ к следующим действиям:</p>
                    <ul className={styles.paragraph}>
                        <li>Регистрация аккаунта в Сервисе с возможностью смены тарифного плана или удаления учетной записи в случае необходимости;</li>
                        <li>Создание, изменение и удаление Магазина;</li>
                        <li>Добавление, изменение и удаление товаров или услуг в Магазин;</li>
                        <li>Создание, изменение и удаление баннеров или акций;</li>
                        <li>Информация и аналитика по полученным заказам от Пользователей;</li>
                        <li>Право на оказание технической поддержки.</li>
                    </ul>
                </ul>

                <h1 className={styles.centeredHeader}>3. Регистрация Продавца</h1>
                <ul className={styles.paragraph}>
                    <p>3.1.Под учетной записью подразумевается аккаунт пользователя в личном кабинете Сервиса. Регистрация происходит на официальном сайте по адресу <Link to='https://admin.thebotique.ru/register'>https://admin.thebotique.ru/register</Link>.</p>
                    <p>3.2. Во время регистрации Продавец заполняет необходимые поля персональными данными (имя, электронная почта, телефон и т.д.) и указывает пароль, который в дальнейшем будет использоваться для доступа к аккаунту. Продавцу запрещается передавать третьим лицам свой логин и пароль, Продавец несет полную ответственность за сохранность персонального логина и пароля.</p>
                    <p>3.3. После отправки формы регистрации Продавцу на электронную почту приходит подтверждение адреса электронной почты. После подтверждения электронной почты Продавец переходит на страницу авторизации и входит в учетную запись Продавца в Сервисе.</p>
                    <p>3.4. Все персональные данные подпадают под действие Политики обработки персональных данных Сервиса и могут быть использованы, как контактные данные Продавца.</p>
                </ul>

                <h1 className={styles.centeredHeader}>4. Управление аккаунтом Продавца</h1>
                <ul className={styles.paragraph}>
                    <p>4.1. Доступ к уведомлениям и кнопкам быстрого доступа к функционалу Сервиса, статистика по продажам, Пользователям, Магазинам и др. — вся эта информация отображается Продавцу в личном кабинете. Также в личном кабинете Продавец может изменить информацию о Продавце, выбрать и подключить тариф, указать реквизиты для поступлений от реализации товаров или услуг в Магазине Продавца.</p>
                    <p>4.2. Продавец имеет право в любой момент отправить запрос на удаление личного кабинета.</p>
                </ul>

                <h1 className={styles.centeredHeader}>5. Создание и управление Магазином</h1>
                <ul className={styles.paragraph}>
                    <p>5.1. Продавец, используя функционал Сервиса, создает Магазин, вводит все запрашиваемые Сервисом данные (в том числе уведомляет о своей системе налогообложения).</p>
                    <p>5.2. Продавец управляет Магазином посредством получения доступа к следующим действиям: скрыть, отображать, удалять, редактировать Магазин, добавлять и изменять товары или услуги.</p>
                    <p>5.3. В зависимости от выбранного типа Магазина (магазин товаров или магазин услуг), у Продавца 2 варианта отображения страницы добавления товара (услуги). В Магазин товаров Продавец добавляет наименование товара, фотографии, работает с дополнительными характеристиками товара, выбирает свою систему налогообложения НДС 0-10-20%. В Магазине услуг Продавец создает список  услуг (добавляя описание и фотографии) и специалистов (добавляя фотографии, описание), указывает время работы для каждого специалиста, сопоставляет специалистов и услуги. Продавец управляет созданными элементами: может их изменять, удалять, скрывать, отображать.</p>
                    <p>5.4. Продавец является плательщиком соответствующего налога и самостоятельно оплачивает налог на свой доход. Сервис не имеет отношения к осуществлению оплаты налогов и страховых взносов Продавца.</p>
                </ul>

                <h1 className={styles.centeredHeader}>6. Ограничения на продажу товаров</h1>
                <ul className={styles.paragraph}>
                    <p>6.1. Категории товаров, по которым требуется подтверждение качества:</p>
                    <ul className={styles.paragraph}>
                        <li>Витамины и БАДы: свидетельство о государственной регистрации (в соответствии с ТР ТС 021/2011 Технический регламент Таможенного союза “О безопасности пищевой продукции”);</li>
                        <li>Спортивное питание: свидетельство о государственной регистрации или декларация о соответствии (в соответствии с ТР ТС 021/2011 Технический регламент Таможенного союза “О безопасности пищевой продукции”,  ГОСТ 34006-2016 «Продукция пищевая специализированная. Продукция пищевая для питания спортсменов. Термины и определения»);</li>
                        <li>Экспресс-тесты: регистрационное удостоверение на каждое медицинское изделие и информацию, установленную в соответствии с Постановлением Правительства РФ от 31.12.2020 N 2463;</li>
                        <li>Парафармацевтика: свидетельство о государственной регистрации, декларация о соответствии (в соответствии с ТР ТС 009/2011. Технический регламент Таможенного союза «О безопасности парфюмерно-косметической продукции», ТР ТС 021/2011. Технический регламент Таможенного союза «О безопасности пищевой продукции», ТР ТС 027/2012. Технический регламент Таможенного союза «О безопасности отдельных видов специализированной пищевой продукции, в том числе диетического лечебного и диетического профилактического питания»).</li>
                        <li>Детские удерживающие устройства: сертификат соответствия (в соответствии с ТР ТС 018/2011 «О безопасности колесных транспортных средств»);</li>
                        <li>Пиротехника: сертификат соответствия (в соответствии с ТР ТС 006/2011 Технический регламент Таможенного союза «О безопасности пиротехнических изделий»);</li>
                        <li>Медицинские изделия: регистрационное удостоверение (в соответствии с Федеральным законом от 21.11.2011 N 323-ФЗ "Об основах охраны здоровья граждан в Российской Федерации");</li>
                        <li>Детское питание: декларация о соответствии (в соответствии с Регламентом Таможенного союза 021/2011 «О безопасности пищевой продукции».</li>
                    </ul>
                    <p>6.2.	Категории товаров, которые запрещены к продаже дистанционным способом в соответствии с Постановлением Правительства РФ от 27.09.2007 № 612 «Об утверждении Правил продажи товаров дистанционным способом» и с настоящими Правилами:</p>
                    <ul className={styles.paragraph}>
                        <li>Алкогольная продукция;</li>
                        <li>Драгоценные и редкоземельные металлы;</li>
                        <li>Ювелирные изделия;</li>
                        <li>Драгоценные камни;</li>
                        <li>Вооружение, боеприпасы к нему, взрывчатые вещества и изделия, средства взрывания, патроны, порох;</li>
                        <li>Радиоактивные материалы, рентгеновское оборудование и приборы, работающие с использованием радиоактивных веществ и изотопов.</li>
                        <li>Продукция эротического и порнографического характера;</li>
                        <li>Шифровальная техника;</li>
                        <li>Яды, наркотические средства и психотропные вещества, их прекурсоры;</li>
                        <li>Продукция, произведённая из токсичных материалов;</li>
                        <li>Спирт этиловый и иная спиртосодержащая продукция;</li>
                        <li>Специальные и иные технические средства, предназначенные для негласного получения информации, в том числе:</li>
                        <ul className={styles.paragraph}>
                            <li>детские игрушки, в глаза которых встроены скрытые видеокамеры;</li>
                            <li>видеокамеры, замаскированные под другие предметы либо предназначенные для скрытой установки;</li>
                            <li>диктофоны, замаскированные под другие предметы;</li>
                            <li>скрытые GPS-трекеры;</li>
                        </ul>
                        <li>Все виды оружия;</li>
                        <li>Холодное оружие:</li>
                        <ul className={styles.paragraph}>
                            <li>кинжалы;</li>
                            <li>боевые, национальные, охотничьи ножи;</li>
                            <li>штык-ножи;</li>
                            <li>сабли, шашки, мечи и тому подобное;</li>
                            <li>иное оружие режущего, колющего, рубящего или смешанного действия (штыки, копья, боевые топоры и тому подобное);</li>
                            <li>оружие ударно-дробящего действия (кастеты, нунчаки, кистени и тому подобное).</li>
                        </ul>
                        <li>Товары с дульной энергией более 3 Дж;</li>
                        <li>Товары, являющиеся объектами культурного наследия;</li>
                        <li>Спецсредства травматического и удерживающего действия;</li>
                        <li>Пестициды и агрохимикаты;</li>
                        <li>Продукция, полученная в результате браконьерской деятельности;</li>
                        <li>Сети рыболовные;</li>
                        <li>Электроловильные системы и устройства вылова посредством электрического тока;</li>
                        <li>Капканы и самоловы;</li>
                        <li>Табачная продукция;</li>
                        <li>Устройства для потребления никотиносодержащей продукции, электронные сигареты, системы нагревания табака и аксессуары к ним:</li>
                        <ul className={styles.paragraph}>
                            <li>растворы;</li>
                            <li>жидкости;</li>
                            <li>смеси или гели;</li>
                            <li>безникотиновые жидкости.</li>
                        </ul>
                        <li>Кальяны в сборном виде и более двух частей в наборе;</li>
                        <li>Шланги для кальянов;</li>
                        <li>Табак для кальяна, а также бестабачные смеси для кальяна с содержанием никотина;</li>
                        <li>Лекарственные средства, витамины, БАД (исключение: БАД с российской сертификацией);</li>
                        <li>Ветеринарные препараты;</li>
                        <li>Витамины для животных;</li>
                        <li>Тесты на COVID-19;</li>
                        <li>Продукты питания, которые не соответствуют правилам перевозки, например, температурному режиму;</li>
                        <li>Живые растения, семена растений (в том числе корни, луковицы, клубни растений);</li>
                        <li>Адаптеры ремня безопасности, в том числе:</li>
                        <ul className={styles.paragraph}>
                            <li>корректор лямок ремней безопасности;</li>
                            <li>адаптер лямок ремней безопасности;</li>
                            <li>направляющая лямка;</li>
                            <li>накладка на ремень безопасности;</li>
                            <li>треугольник на ремень безопасности.</li>
                        </ul>
                        <li>Бескаркасные детские кресла;</li>
                        <li>Дорожные знаки;</li>
                        <li>Живые животные и насекомые;</li>
                        <li>Газовые баллончики для самообороны, а также любые механические распылители, аэрозольные и другие устройства, снаряжённые слезоточивыми или раздражающими веществами;</li>
                        <li>Ртутные термометры;</li>
                        <li>Попперсы;</li>
                        <li>БАД, в состав которых входят растения и продукты их переработки, содержащие психотропные, наркотические, сильнодействующие или ядовитые вещества;</li>
                        <li>Пиротехнические изделия IV и V классов опасности;</li>
                        <li>Корм и кормовые добавки для животных;</li>
                        <li>Ошейники для коррекции поведения животных с электрическим, ультразвуковым или звуковым воздействием;</li>
                        <li>Универсальные ключи для домофонов, которые могут быть использованы без уникальной настройки («ключи-вездеходы»);</li>
                        <li>Товары, внешний вид (в том числе размещённые на них изображения) или содержание которых включают в себя:</li>
                        <ul className={styles.paragraph}>
                            <li>пропаганду наркотических средств, психотропных веществ и их прекурсоров;</li>
                            <li>пропаганду жестокого обращения с животными;</li>
                            <li>пропаганду либо публичное демонстрирование нацистской атрибутики или символики, либо атрибутики или символики, сходных с нацистской атрибутикой или символикой до степени смешения, либо атрибутики или символики экстремистских организаций, либо иных атрибутики или символики, пропаганда либо публичное демонстрирование которых запрещены федеральными законами (за исключением случаев использования такой атрибутики или символики, при которых формируется негативное отношение к идеологии нацизма и экстремизма и отсутствуют признаки пропаганды или оправдания нацистской и экстремистской идеологии);</li>
                            <li>пропаганду нетрадиционных сексуальных отношений, смены пола, педофилии.</li>
                        </ul>
                        <li>Инфекционные вещества:</li>
                        <ul className={styles.paragraph}>
                            <li>биологические материалы;</li>
                            <li>инъекционные растворы;</li>
                            <li>иммунобиологические препараты и тому подобное.</li>

                        </ul>
                        <li>Товары, предназначенные для ритуала погребения:</li>
                        <ul className={styles.paragraph}>
                            <li>гробы;</li>
                            <li>текстильные наборы и элементы одежды;</li>
                            <li>аксессуары для захоронения.</li>
                        </ul>
                        <li>Человеческие останки и прах.</li>
                        <li>Пищевая продукция, содержащая в своем составе мухоморы.</li>
                    </ul>
                    <p>6.3. Товары, размещенные в Магазине без подтверждения качества или являющиеся запрещенными к продаже дистанционным способ, удаляются во время модерации.</p>
                </ul>

                <h1 className={styles.centeredHeader}>7. Создание баннера или акции</h1>
                <ul className={styles.paragraph}>
                    <p>7.1. Продавец добавляет фотографию или текст, а также изображение для баннера или акции, соответственно. В Магазине указывается диапазон видимости баннера или акции.</p>
                </ul>

                <h1 className={styles.centeredHeader}>8. Список заказов</h1>
                <ul className={styles.paragraph}>
                    <p>8.1. Продавец получает список заказов с возможностью детального просмотра информации о заказе: для товаров - время, сумма, информация о том, кто создал заказ (все данные), заказанные товары; для услуг - календарь с записями, детальная информация по каждому календарному дню (специалист, услуга, время, сумма и т.д.).</p>
                </ul>

                <h1 className={styles.centeredHeader}>9. Доставка заказов</h1>
                <ul className={styles.paragraph}>
                    <p>9.1. В функционал Сервиса встроено API сервиса доставки Боксберри. Продавец получает возможность подключить организацию транспортно-экспедиционных услуг сервисом Боксберри после присоединения к публичной оферте, размещенной по адресу: https://boxberry.ru/e-commerce/dokumenty/dokumenty-i-tarify#dogovory и получения токена.</p>
                    <p>9.2. Требования к упаковке товаров, соблюдение условий Федерального закона от 30.06.2003 № 87-ФЗ «О транспортно-экспедиционной деятельности» и др, а также соблюдение условий публичной оферты с Боксберри предъявляются непосредственно к Продавцу и не распространяются на Сервис.</p>
                </ul>
            </div >
            <Footer />
        </>
    );
};

export default TermsOfUse;
