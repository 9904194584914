import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './DocumentPage.module.css';
import Header from './components/Header';

const PrivacyPolicyAgreement = () => {

    useEffect(() => {
        document.title = "Согласие на обработку персональных данных";
    }, []);

    const nav = useNavigate();


    const handleRetun = () => {
        nav(-1);
    };

    return (
        <>
            <Header />
            <div className={styles.agreementPage}>
                <h1 className={styles.centeredHeader}>Согласие на обработку персональных данных</h1>

                <div className={styles.paragraph}>
                    Настоящим я даю свое согласие Индивидуальному предпринимателю Кашапову Артему Ильясовичу (далее - Оператор) на обработку моих персональных данных Сервисом «Botique», размещенному на сайте в сети Интернет по адресу thebotique.ru (далее - Сервис) и его поддоменах, в соответствии с требованиями Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных» в следующих целях:
                    <ul className={styles.paragraph}>
                        <li>регистрация аккаунта Продавца на Сервисе;</li>
                        <li>информирование Пользователя посредством отправки электронных писем;</li>
                        <li>информационная и/или рекламная рассылка;</li>
                        <li>заключение, исполнение и прекращение гражданско-правовых договоров;</li>
                        <li>предоставление доступа Продавцу к сервисам, информации и/или материалам, содержащимся на Сервисе и его поддоменах.</li>
                    </ul>
                </div>
                <p className={styles.paragraph}>
                    Я принимаю все условия Политики обработки персональных данных, размещенной по адресу: <Link to={'/privacy-policy'}>https://docs.thebotique.ru/privacy-policy</Link>, и даю свое согласие Оператору на обработку, в том числе автоматизированную (смешанный способ обработки персональных данных), персональных данных в соответствии с Федеральным законом от 27.07.2006 г. № 152-ФЗ «О персональных данных», включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), блокирование, удаление, уничтожение персональных данных.
                </p>
                <p className={styles.paragraph}>
                    Я обязуюсь принимать меры для обеспечения безопасности и сохранности персональных данных, в том числе необходимые для использования Сервиса, как, например, использовать антивирусы, заботиться о сохранении паролей и недоступности их для третьей стороны. Я обязуюсь возместить Оператору любой ущерб и убытки (в том числе в связи с применением к Оператору мер административной или иной ответственности), возникшие из-за использования мной чужих персональных данных.
                </p>
                <p className={styles.paragraph}>
                    Я уведомлен(а), что Оператор оставляет за собой право передать информацию о Пользователе, нарушившем данное требование в правоохранительные органы с целью защиты прав и законных интересов других субъектов персональных данных и Оператора.
                </p>
                <p className={styles.paragraph}>
                    Я обязуюсь не использовать персональные данные третьих лиц при заполнении форм и при подписке на электронные рассылки Сервиса.
                </p>

                <div className={styles.paragraph}>
                    Выражаю полное и безоговорочное согласие на использование моих данных для поддержания связи со мной любым способом, включая отправку электронных писем на указанный мной электронный адрес с целью информирования о новых услугах, новых информационных материалов, мероприятиях и другой информации соответствующей тематике Сервиса.
                    <ul className={styles.paragraph}>
                        Я даю свое согласие на обработку моих персональных данных:
                        <p>- фамилию, имя, отчество</p>
                        <p>- номер телефона</p>
                        <p>- адрес электронной почты</p>
                        <p>- профили в социальных сетях</p>
                    </ul>
                </div>

                <p className={styles.paragraph}>Я проинформирован(а) о том, что данное согласие может быть отозвано мной в любой момент путем направления письма на электронную почту: support@thebotique.ru и действует с момента его предоставления Оператору до даты моего отзыва согласия.</p>
                <button className={styles.downloadButton} onClick={handleRetun}>Назад</button>
            </div>
        </>
    );
};

export default PrivacyPolicyAgreement;
