import React from 'react';
import styles from './DocumentationSection.module.css'; // Импорт модульных стилей

const DocumentationSection = ({ endpoint, method, description, exampleCode, fields }) => {
    return (
        <div className={styles.documentationSection}>
            <h2 className={styles.endpoint}>{endpoint}</h2>
            <p><strong className={styles.method}>Метод:</strong> {method}</p>
            <p className={styles.description}>{description}</p>
            <pre className={styles.codeBlock}>
                <code>{exampleCode}</code>
            </pre>
            <h3>Описание полей:</h3>
            <ul className={styles.fieldList}>
                {fields.map((field, index) => (
                    <li key={index} className={styles.fieldItem}>
                        <span className={styles.fieldName}>{field.name}</span> (<span className={styles.fieldType}>{field.type}</span>)
                        {field.required && <span className={styles.required}>*</span>} - {field.description}
                        {field.choices && (
                            <div className={styles.choices}>
                                Доступные значения: {field.choices.join(', ')}
                            </div>
                        )}
                        {field.maxLength && (
                            <div className={styles.maxLength}>
                                {`Максимальная длина: ${field.maxLength} символов`}
                            </div>
                        )}
                        {field.minValue !== undefined && (
                            <div className={styles.minValue}>
                                Минимальное значение: {field.minValue}
                            </div>
                        )}
                        {field.subfields && (
                            <ul className={styles.subfieldsList}>
                                {field.subfields.map((subfield, subIndex) => (
                                    <li key={subIndex} className={styles.subfieldItem}>
                                        <span className={styles.subfieldName}>{subfield.name}</span> (<span className={styles.subfieldType}>{subfield.type}</span>)
                                        {subfield.required && <span className={styles.required}>*</span>} - {subfield.description}
                                        {subfield.maxLength && (
                                            <div className={styles.subfieldMaxLength}>
                                                {`Максимальная длина: ${subfield.maxLength} символов`}
                                            </div>
                                        )}
                                        {subfield.minValue !== undefined && (
                                            <div className={styles.subfieldMinValue}>
                                                Минимальное значение: {subfield.minValue}
                                            </div>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DocumentationSection;