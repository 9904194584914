import React, { useEffect, useRef } from 'react';
import styles from './DocumentPage.module.css';
import Footer from './components/Footer';
import Header from './components/Header';
import NavigationMenu from './components/NavigationMenu';
import UpButton from './components/UpButton';
const ReturnRules = () => {
    useEffect(() => {
        document.title = "Правила возврата товаров";
    }, [])
    const contRef = useRef();
    return (
        <>
            <Header />
            <NavigationMenu />
            <div className={styles.licensePage} ref={contRef}>
                <UpButton href={contRef} />
                <h1 className={styles.centeredHeader}>Правила возврата товаров, приобретенных через Сервис «Botique»</h1>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>Российская Федерация, город Самара</p>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>Дата размещения: 4 декабря 2023 года</p>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>Дата вступления в силу: 4 декабря 2023 года</p>

                <h1 className={styles.centeredHeader}>Термины и определения:</h1>
                <ul className={styles.paragraph}>
                    <p><strong className={styles.boldText}>Продавец</strong> - юридическое лицо и индивидуальный предприниматель, действующие в лице своих уполномоченных представителей, принявшие оферту на заключение Договора, в порядке предусмотренном в настоящем документе.</p>
                    <p><strong className={styles.boldText}>Пользователь</strong> – полностью дееспособное физическое лицо, использующее Магазин Продавца для приобретения товаров и/или услуг Продавца.</p>
                    <p><strong className={styles.boldText}>Сервис «Botique»/Сервис</strong> – программное обеспечение «Botique», размещенное на сайте в сети Интернет по адресу thebotique.ru предназначенное для создания и управления Магазинами в мессенджере Telegram.</p>
                    <p><strong className={styles.boldText}>Магазин</strong> - средство обмена электронными сообщениями (чат) в мессенджере Telegram, создаваемый Продавцом в автоматизированном режиме посредством использования Сервиса в целях осуществления Продавцом продажи товаров и/или оказания услуг Пользователям.</p>

                </ul>
                <h1 className={styles.centeredHeader}>1. Общие положения</h1>
                <ul className={styles.paragraph}>
                    <p>1.1. Пользователь имеет возможность осуществить возврат приобретенных через Сервис качественных товаров (то есть товаров без дефектов) в течение 14 дней с момента покупки, при условии соблюдения ряда требований, установленных п. 2 настоящих Правил.</p>
                    <p>1.2. Пользователь имеет возможность осуществить возврат приобретенных через Сервис некачественных товаров (то есть не тот товар, товар с дефектами, некомплектный товар, не тот размер или цвет товара, истек срок годности, поврежденный товар при доставке) в течение 3 дней с момента покупки.</p>
                    <p>1.3. Пользователь имеет возможность осуществить возврат приобретенных через Сервис товаров в течение гарантийного срока, если таковой установлен.</p>
                    <p>1.4. Продавец устанавливает правила возврата товаров, приобретенных непосредственно в его Магазине, они не должны противоречить настоящим Правилам. Вся ответственность за возврат товара и соблюдения законодательства РФ в сфере защиты прав потребителей лежит на Продавце.</p>
                </ul>
                <h1 className={styles.centeredHeader}>2. Требования, предъявляемые к товару для возврата</h1>
                <ul className={styles.paragraph}>
                    <p>2.1.	Пользователь может осуществить возврат товара, приобретенного через Сервис, в течение 14 дней, если изделие не попадает в число установленных Постановлением Правительства РФ № 2463 от 31 декабря 2020 г. исключений, к таковым относятся следующие категории товаров:</p>
                    <ul className={styles.paragraph}>
                        <li>Продукты питания;</li>
                        <li>Лекарства и другие медицинские товары;</li>
                        <li>Косметика, парфюмерия, предметы личной гигиены и бытовая химия;</li>
                        <li>Измеряемые и продаваемые по метражу товары, в частности ткани или провода;</li>
                        <li>Возврату также не подлежат швейные изделия, трикотаж;</li>
                        <li>Мебель в гарнитурах и комплектах;</li>
                        <li>Транспортные средства;</li>
                        <li>Товары из класса бытовой техники и электроники;</li>
                        <li>Издания, которые не относятся к числу периодических.</li>
                    </ul>
                    <p>2.2. Товар сохраняет то же состояние, в котором находился после покупки, не имеет следов использования.</p>
                    <p>2.3. Упаковка сохранена, бирки, этикетки и пломбы на месте, не сорваны, не повреждены.</p>
                    <p>2.4. В момент подачи заявления Пользователя о возврате товара, Сервис или Продавец могут потребовать  предъявить доказательства покупки (чек), а также, в случае возврата товара в течение гарантийного срока, оригинал акта (заключения) авторизованного сервисного центра или эксперта, подтверждающего, что недостаток производственный, а также оригинал гарантийного талона при его наличии, при этом, если товар является технически сложным, в заключении должно быть подтверждение, что дефект существенный.</p>
                </ul>

                <h1 className={styles.centeredHeader}>3. Процедура возврата товара, купленного через Сервис</h1>
                <ul className={styles.paragraph}>
                    <p>3.1. Пользователь может отменить покупку Товара до момента получения уведомления об изменении Продавцом статуса Заказа на «Подтвержден».</p>
                    <p>3.2. Для оформления возврата Пользователю необходимо в течение 14 (четырнадцати) дней обратиться к поддержке Сервиса через чат-бот.</p>
                    <p>3.3. Возврат денежных средств за ранее приобретенный товар осуществляется в течение 30 календарных дней на тот же счет, с которого Пользователь осуществлял покупку. Если оплата произведена наличными, то денежные средства могут быть возвращены наличными или перечислены на банковский счет Пользователя, указанный в заявлении по выбору Продавца.</p>
                </ul>
            </div >
            <Footer />
        </>
    );
};

export default ReturnRules;
