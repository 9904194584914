import React from 'react';
import CardContainer from './components/CardContainer';
import Footer from './components/Footer';
import Header from './components/Header';

function Main() {
    return (
        <>
            <Header />
            <CardContainer />
            <Footer />
        </>
    );
}

export default Main;
