import React, { useEffect } from 'react';
import styles from './DocumentPage.module.css';
// import Footer from './components/Footer';
import Header from './components/Header';
import NavigationMenu from './components/NavigationMenu';

const CharacteristicsPage = () => {
    useEffect(() => {
        document.title = "Перечень доступных характеристик товаров на Сервисе «Botique»";
    }, []);

    return (
        <>
            <Header />
            <NavigationMenu />
            <div className={styles.licensePage}>
                <h1 className={styles.centeredHeader}>Перечень доступных характеристик товаров на Сервисе «Botique»</h1>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>На данной странице представлен список доступных характеристик товаров.</p>
                <ul className={styles.paragraph}>
                    <li>Артикул</li><li>Бренд</li><li>Вес товара, г</li><li>Вес, гр</li><li>Вид</li><li>Вид ароматизатора</li><li>Вид лампы</li><li>Вид питания</li><li>Вкус корма для животных</li><li>Возраст животного</li><li>Время</li><li>Дата и время</li><li>Длина, см</li><li>Допуски и разрешения</li><li>Классификация по ACEA</li><li>Классификация по API</li><li>Количество</li><li>Количество в упаковке</li><li>Количество в упаковке, шт</li><li>Комплектация</li><li>Максимальная мощность, Вт</li><li>Максимальный возраст ребенка</li><li>Материал</li><li>Минимальный возраст ребенка</li><li>Название аромата</li><li>Объем, л</li><li>Объем, мл</li><li>Основной ингредиент</li><li>Особые потребности</li><li>Плотность</li><li>Пол</li><li>Применение</li><li>Размер</li><li>Размер животного</li><li>Размер производителя</li><li>Размер, мм</li><li>Российский размер</li><li>Сезон</li><li>Соответствует требованиям</li><li>Состав материала</li><li>Спецификации</li><li>Срок годности в днях</li><li>Стиль</li><li>Страна-изготовитель</li><li>Температура света, К</li><li>Тип</li><li>Типоразмер</li><li>Уровень эффективности</li><li>Форма выпуска</li><li>Цвет</li>
                </ul>
                <div>
                </div>
            </div >
            {/* <Footer /> */}
        </>
    );
};

export default CharacteristicsPage;
