import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from './svg_vector_1.svg';

const Header = () => {

    const [hideBtn, setHideBtn] = useState(false);

    useEffect(() => {
        if (window.location.pathname === '/privacy-policy-agreement') {
            setHideBtn(true);
        }
    }, [])
    return (
        <header className="app-header">
            <div className="left-section" onClick={() => { window.location = '/'; }}>
                <img src={logo} alt="Logo" className="logo" />
                <hr />
                <span>
                    DOCUMENTS
                </span>
            </div>
            <div className="right-section">

                {
                    hideBtn ? (null) : <>
                        <Link className="button" to={'https://thebotique.ru/'}>Перейти на сайт</Link>
                        <Link className="button" to={'https://admin.thebotique.ru/'}>Авторизация</Link>
                    </>
                }
            </div>
        </header>
    );
};

export default Header;
