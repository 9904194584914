import React, { useEffect } from 'react';
import styles from './DocumentPage.module.css';
import Footer from './components/Footer';
import Header from './components/Header';
import NavigationMenu from './components/NavigationMenu';

const CategoriesPage = () => {
    useEffect(() => {
        document.title = "Перечень доступных категорий товаров на Сервисе «Botique»";
    }, []);

    return (
        <>
            <Header />
            <NavigationMenu />
            <div className={styles.licensePage}>
                <h1 className={styles.centeredHeader}>Перечень доступных категорий товаров на Сервисе «Botique»</h1>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>На данной странице представлен список доступных категорий и подкатегорий товаров.</p>
                <ul className={styles.paragraph}>
                    <li>Автотовары</li>
                    <ul className={styles.paragraph}>
                        <li>Автозапчасти</li>
                        <li>Автозвук</li>
                        <li>Гараж и автосервис</li>
                        <li>Автоаксессуары и принадлежности</li>
                        <li>Масла и автохимия</li>
                        <li>Шины и диски</li>
                        <li>Уход за автомобилем</li>
                        <li>Инструменты и оборудование</li>
                        <li>Аккумуляторы и аксессуары</li>
                        <li>Мототовары</li>
                        <li>Автопарфюм</li>
                    </ul>

                    <li>Детские товары</li>
                    <ul className={styles.paragraph}>
                        <li>Игрушки и игры</li>
                        <li>Спорт и игры на улице</li>
                        <li>Подгузники и гигиена</li>
                        <li>Детская комната</li>
                        <li>Коляски и автокресла</li>
                        <li>Детское питание</li>
                        <li>Товары для кормления</li>
                        <li>Школа и обучение</li>
                        <li>Товары для мам</li>
                    </ul>

                    <li>Дом и сад</li>
                    <ul className={styles.paragraph}>
                        <li>Дача и сад</li><li>Декор и интерьер</li><li>Товары для праздников</li><li>Посуда</li><li>Кухонные принадлежности</li><li>Текстиль</li><li>Освещение</li><li>Цветы, растения, горшки</li><li>Товары для бани и сауны</li><li>Хозяйственные товары</li><li>Хранение вещей</li><li>Мебель</li><li>Инструменты и оборудование</li>
                    </ul>

                    <li>Красота и здоровье</li>
                    <ul className={styles.paragraph}>
                        <li>Загар и защита от солнца</li><li>Уход за волосами</li><li>Макияж</li><li>Детская косметика</li><li>Маникюр и педикюр</li><li>Беременным и кормящим</li><li>Уход за лицом</li><li>Парфюмерия</li><li>Уход за телом</li><li>Мужская косметика</li>
                    </ul>

                    <li>Образование и Обучение</li>
                    <ul className={styles.paragraph}>
                        <li>Математика</li><li>Физика</li><li>Химия</li><li>Английский язык</li><li>Русский язык и литература</li><li>Информатика и программирование</li><li>Биология</li><li>История</li><li>География</li><li>Экономика и бизнес</li><li>Иностранные языки</li><li>Домашние задания</li><li>Курсовые работы</li><li>Контрольные работы</li><li>Презентации и рефераты</li><li>Тесты и экзамены</li>
                    </ul>

                    <li>Одежда и обувь</li>
                    <ul className={styles.paragraph}>
                        <li>Женщинам</li><li>Мужчинам</li><li>Детям</li><li>Уход за одеждой</li><li>Рабочая одежда</li><li>Обувь</li><li>Верхняя одежда</li><li>Футболки и майки</li><li>Джинсы и брюки</li><li>Платья и юбки</li><li>Аксессуары</li>
                    </ul>

                    <li>Продукты питания</li>
                    <ul className={styles.paragraph}>
                        <li>Фрукты и овощи</li><li>Мясо и рыба</li><li>Молочные продукты</li><li>Замороженные продукты</li><li>Здоровое питание</li><li>Диетические продукты</li>
                    </ul>

                    <li>Спорт и отдых</li>
                    <ul className={styles.paragraph}>
                        <li>Велоспорт</li><li>Плавание</li><li>Гидрокостюмы</li><li>Каякинг</li><li>Аквафитнес и водное поло</li><li>Тренажеры</li><li>Самокаты и аксессуары</li><li>Бассейны</li><li>Фитнес и йога</li><li>Спортивная защита и экипировка</li><li>Электротранспорт</li><li>Футбол</li><li>Баскетбол</li><li>Волейбол</li><li>Художественная гимнастика</li><li>Спортивная гимнастика</li><li>Конный спорт</li><li>Тяжелая атлетика</li><li>Бейсбол</li><li>Пейнтбол</li><li>Гольф</li><li>Электротранспорт</li><li>Спортивная защита и экипировка</li><li>Бокс и единоборства</li><li>Скейтбординг</li><li>Спортивное питание</li><li>Теннис и бадминтон</li><li>Бег</li><li>Хоккей</li><li>Беговые лыжи</li><li>Сноуборды</li><li>Горные лыжи</li><li>Одежда для лыж и сноубординга</li><li>Маски и очки</li><li>Обработка лыж и сноубордов</li><li>Лыжные палки</li><li>Фигурные коньки</li><li>Конькобежный спорт</li><li>Снегоходы и мотобуксировщики</li><li>Тюбинги</li>
                    </ul>

                    <li>Товары для животных</li>
                    <ul className={styles.paragraph}>
                        <li>Для кошек</li><li>Для собак</li><li>Товары для грызунов и хорьков</li><li>Для птиц</li><li>Для рыб и рептилий</li><li>Ветаптека</li><li>Для рыб и рептилий</li><li>Для лошадей</li><li>Фермерское хозяйство</li>
                    </ul>

                    <li>Хобби и творчество</li>
                    <ul className={styles.paragraph}>
                        <li>Рукоделие</li><li>Раскрашивание и роспись</li><li>3D ручки и аксессуары</li><li>Настольные и карточные игры</li><li>Создание картин и фоторамок</li><li>Оригами и поделки из бумаги</li><li>Пазлы и головоломки</li><li>Музыкальные инструменты</li><li>Рисование</li><li>Гончарное дело</li><li>Лепка и скульптура</li><li>Гравюры</li><li>Мозаика и фреска</li><li>Подарочные и новогодние наборы</li><li>Фокусы</li><li>Наборы для мастер-класса</li><li>Изготовление свечей</li><li>Изготовление косметики и духов</li>
                    </ul>

                    <li>Цветы</li>
                    <ul className={styles.paragraph}>
                        <li>Букеты</li><li>Композиции</li><li>Цветочные аксессуары</li><li>Упаковка и подарки</li><li>Растения</li><li>Сопутствующие товары</li>
                    </ul>


                    <li>Цифровые товары</li>
                    <ul className={styles.paragraph}>
                        <li>Электронные сертификаты</li><li>Онлайн-подписки</li><li>Игры и игровые подписки</li><li>Облачные хранилища</li><li>Музыка</li><li>Электронные и аудиокниги</li><li>Онлайн курсы</li><li>Программное обеспечение</li><li>Услуги</li>                    </ul>

                    <li>Электроника</li>
                    <ul className={styles.paragraph}>
                        <li>Смартфоны и планшеты</li><li>Ноутбуки и компьютеры</li><li>Фото- и видеотехника</li><li>Телевизоры и аудиоаппаратура</li><li>Аксессуары для электроники</li>
                    </ul>

                    <li>Другое</li>
                    <ul className={styles.paragraph}>
                        <li>Другое</li>
                    </ul>
                </ul>
                <div>
                </div>
            </div >
            <Footer />
        </>
    );
};

export default CategoriesPage;
