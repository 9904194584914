import React from 'react';
import DocumentationSection from '../components/DocumentationSection';

const NewProductDoc = () => {

    const endpoint = "/api/v1/ext/new-item/";
    const method = "POST";
    const description = "Создание нового товара через API. Пример:";
    const exampleCode = `curl --location 'base${endpoint}' \\
  --header 'Content-Type: application/json' \\
  --data '{
      "token": "token",
      "tax": "vat10",
      "state": true,
      "category": "Одежда и обувь",
      "subcategory": "Обувь",
      "characteristics": [
          {
              "characteristic": "Цвет",
              "value": "Черный"
          }
      ],
      "name": "Название товара",
      "description": "Описание товара",
      "stock": 10,
      "sku": "sku",
      "price": 100000,
      "total_price": 85300,
      "weight": 100,
      "depth": 20,
      "height": 5,
      "width": 40,
      "photos": [
          {
              "url": "url"
          }
      ]
  }'`;

    // Описание всех полей из вашего сериализатора Django
    const fields = [
        { name: 'token', type: 'string', required: true, description: 'API токен.' },
        { name: 'tax', type: 'string', required: true, description: 'Ставка НДС.', choices: ['vat0', 'vat10', 'vat20'] },
        { name: 'state', type: 'boolean', required: true, description: 'Состояние товара.', choices: ['true - опубликован', 'false - скрыт'] },
        { name: 'category', type: 'string', required: true, description: 'Категория товара (В соответствии с доступным перечнем).' },
        { name: 'subcategory', type: 'string', required: true, description: 'Подкатегория товара (В соответствии с доступным перечнем).' },
        {
            name: 'characteristics', type: 'array', required: false, description: 'Дополнительные характеристики товара.', subfields: [
                { name: 'characteristic', type: 'string', required: true, description: 'Название характеристики.' },
                { name: 'value', type: 'string', required: true, description: 'Значение характеристики.' }
            ]
        },
        { name: 'name', type: 'string', required: true, description: 'Название товара.', maxLength: 128 },
        { name: 'description', type: 'string', required: true, description: 'Описание товара.' },
        { name: 'stock', type: 'integer', required: true, description: 'Количество товара в наличии.', minValue: 0 },
        { name: 'sku', type: 'string', required: true, description: 'Артикул товара.', maxLength: 128 },
        { name: 'price', type: 'integer', required: true, description: 'Цена товара, в копейках.', minValue: 100 },
        { name: 'total_price', type: 'integer', required: true, description: 'Итоговая цена товара, в копейках.', minValue: 100 },
        { name: 'weight', type: 'integer', required: true, description: 'Вес товара, гр.', minValue: 1 },
        { name: 'depth', type: 'integer', required: true, description: 'Глубина упаковки товара, см.', minValue: 1 },
        { name: 'height', type: 'integer', required: true, description: 'Высота упаковки товара, см.', minValue: 1 },
        { name: 'width', type: 'integer', required: true, description: 'Ширина упаковки товара, см.', minValue: 1 },
        {
            name: 'photos', type: 'array', required: false, description: 'Фотографии товара.', subfields: [
                { name: 'url', type: 'string', required: true, description: 'URL фотографии товара.' }
            ]
        }
    ];
    return (
        <DocumentationSection
            endpoint={endpoint}
            method={method}
            description={description}
            exampleCode={exampleCode}
            fields={fields}
        />
    );
};

export default NewProductDoc;
