import React from 'react';
import DocumentationSection from '../components/DocumentationSection';

const UpdateProductDoc = () => {

    const endpoint = "/api/v1/ext/item/update-stock/";
    const method = "POST";
    const description = "Обновление остатка товара через API. Пример:";
    const exampleCode = `curl --location 'base${endpoint}' \\
    --header 'Content-Type: application/json' \\
    --data '{
        "token": "token",
        "sku": "sku",
        "stock": 10
    }'`;

    const fields = [
        { name: 'token', type: 'string', required: true, description: 'API токен.' },
        { name: 'stock', type: 'integer', required: true, description: 'Количество товара в наличии.', minValue: 0 },
        { name: 'sku', type: 'string', required: true, description: 'Артикул товара.', maxLength: 128 },
    ];
    return (
        <DocumentationSection
            endpoint={endpoint}
            method={method}
            description={description}
            exampleCode={exampleCode}
            fields={fields}
        />
    );
};

export default UpdateProductDoc;
