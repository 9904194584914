import React from 'react';
import GetDocumentationSection from '../components/GetDocumentationSection';

const GetProduct = () => {

    const getEndpoint = "/api/v1/ext/item/stock";
    const getMethod = "GET";
    const getDescription = "Получение информации о товаре по артикулу.";

    const queryParams = [
        { name: 'token', type: 'string', required: true, description: 'API токен.' },
        { name: 'sku', type: 'string', required: true, description: 'Артикул товара.', maxLength: 128 },
    ];

    return (
        <GetDocumentationSection
            endpoint={getEndpoint}
            method={getMethod}
            description={getDescription}
            queryParams={queryParams}
        />
    );
};

export default GetProduct;
