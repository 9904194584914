import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";


function Footer({ ref }) {
    return (
        <footer className="footer" ref={ref}>
            <div className="footerRow">
                <span>Botique</span>
                <span>2023-{new Date().getFullYear()} © Все права защищены</span>
            </div>
            <div className={"footerRow"}>
                <Link to={'/license-agreement'} target="_blank">Лицензионный договор</Link>
                <Link to={'/terms-of-use'} target="_blank">Правила использования cервиса</Link>
                <Link to={'/privacy-policy'} target="_blank">Политика обработки персональных данных</Link>
            </div>
        </footer>
    )
}

export default Footer;