import React from 'react';
import './Card.css';

const Card = ({ title, text, onClick }) => {
    return (
        <div className="card" onClick={onClick}>
            <h3>{title}</h3>
            <p>{text}</p>
        </div>
    );
};

export default Card;
