import React from 'react';
import styles from './NotFound.module.css'
import logo from './svg_vector_2.svg';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className={styles.notFoundContainer}>
            <img src={logo} alt="Логотип" className={styles.logo} />
            <h2 className={styles.notFoundHeader}>404 - Страница не найдена</h2>
            <p className={styles.notFoundText}>Извините, страница, которую вы ищете, не существует. Вы можете вернуться на <Link to={'/'}>главную</Link></p>
        </div>
    );
};

export default NotFound;
