import React from 'react';
import styles from './DocumentationSection.module.css';

const GetDocumentationSection = ({ endpoint, method, description, queryParams }) => {
    return (
        <div className={styles.documentationSection}>
            <h2 className={styles.endpoint}>{endpoint}</h2>
            <p><strong className={styles.method}>Метод:</strong> {method}</p>
            <p className={styles.description}>{description}</p>
            <h3>Query параметры:</h3>
            <ul className={styles.fieldList}>
                {queryParams.map((param, index) => (
                    <li key={index} className={styles.fieldItem}>
                        <span className={styles.fieldName}>{param.name}</span> (<span className={styles.fieldType}>{param.type}</span>)
                        {param.required && <span className={styles.required}>*</span>} - {param.description}
                        {param.maxLength && (
                            <div className={styles.maxLength}>
                                Максимальная длина: {param.maxLength}
                            </div>
                        )}
                        {param.minValue !== undefined && (
                            <div className={styles.minValue}>
                                Минимальное значение: {param.minValue}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default GetDocumentationSection;
