import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Предполагается использование React Router для навигации

import './NavigationMenu.css'; // Подключаем файл стилей
import lupa from './lupa.svg';

const NavigationMenu = () => {

    const links = [
        { url: '/', title: 'Главная' },
        { url: '/license-agreement', title: 'Лицензионный договор (оферта)' },
        { url: '/terms-of-use', title: 'Условия использования сервиса' },
        { url: '/return-policy', title: 'Правила возврата товаров' },
        { url: '/privacy-policy', title: 'Политика обработки персональных данных' },
        { url: '/about-tariffs', title: 'Список тарифов и порядок совершения платежей' },
        { url: '/about-api', title: 'Документация API' },
        { url: '/about-categories', title: 'Перечень доступных категорий товаров' },
        { url: '/about-characteristics', title: 'Перечень доступных характеристик товаров' },
    ]

    const [filteredLinks, setFilteredLinks] = useState(links);
    const [searchVal, setSearchVal] = useState('');

    const handleSearch = (e) => {
        const text = e.target.value;
        setSearchVal(text);

        const filter = links.filter((item) => { return item.title.includes(text) });

        setFilteredLinks(filter);
    }

    return (
        <div className="navigation-menu">
            <div className="logo">
                <span>Документация</span>
            </div>
            <div className="search">
                <img src={lupa} alt="Иконка поиска" />
                <input type="text" placeholder="Поиск..." value={searchVal} onChange={(e) => handleSearch(e)} />
            </div>
            <div className="links">
                {filteredLinks.map((item, ind) => (
                    <Link key={ind} to={item.url}>{item.title}</Link>
                ))}
            </div>
        </div>
    );
};

export default NavigationMenu;
