import React, { useEffect, useRef } from 'react';
import styles from './DocumentPage.module.css'
import Header from './components/Header';
import NavigationMenu from './components/NavigationMenu';
import Footer from './components/Footer';
import { Link } from 'react-router-dom';
import UpButton from './components/UpButton';
const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = "Политика обработки персональных данных";
    }, [])
    const contHref = useRef();
    return (
        <>
            <Header />
            <NavigationMenu />
            <div className={styles.licensePage} ref={contHref}>
                <UpButton href={contHref} />
                <h1 className={styles.centeredHeader}>ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>Российская Федерация, город Самара</p>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>Дата размещения: 1 сентября 2023 года</p>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>Дата вступления в силу: 4 декабря 2023 года</p>

                <p className={`${styles.paragraph} ${styles.addPadding}`}>
                    Настоящая политика обработки персональных данных (далее – Политика) разработана в соответствии с Федеральным законом от 27.07.2006. №152-ФЗ «О персональных данных» (далее – ФЗ-152). Настоящая Политика определяет порядок обработки персональных данных физических лиц, полученных в процессе использования Сервиса Botique, меры по обеспечению безопасности персональных данных и порядок хранения персональных данных Индивидуальным предпринимателем Кашаповым Артемом Ильясовичем, ОГРНИП 323631200088051 (далее – Оператор) с целью защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
                </p>

                <p className={`${styles.paragraph} ${styles.addPadding}`}>В Политике используются следующие основные понятия:</p>
                <ul className={styles.paragraph}>
                    <strong className={styles.boldText}>Обработка персональных данных</strong> - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                    <p><strong className={styles.boldText}>Сервис «Botique»/Сервис</strong> – программное обеспечение «Botique», размещенное на сайте в сети Интернет по адресу thebotique.ru, предназначенное для создания и управления Магазинами в мессенджере Telegram.</p>
                    <p><strong className={styles.boldText}>Магазин</strong> - средство обмена электронными сообщениями (чат) в мессенджере Telegram, реализованное как веб-приложение, создаваемое Продавцом в автоматизированном режиме посредством использования Сервиса в целях осуществления Продавцом продажи товаров и/или оказания услуг покупателям.</p>
                    <p><strong className={styles.boldText}>Оператор</strong> – компания, самостоятельно или совместно с другими лицами организующий обработку персональных данных, а также определяющий цели обработки персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
                    <p>Оператором является Индивидуальный предприниматель Кашапов Артем Ильясович, адрес: 443098, Россия, Самарская обл., г. Самара, пр-кт Карла Маркса, д. 472.</p>
                    <p><strong className={styles.boldText}>Продавец</strong> - юридическое лицо, индивидуальный предприниматель или самозанятый гражданин, заключившие договор с Оператором, и реализующие свой товар/услугу через Магазин, созданный на Сервисе Оператора.</p>
                    <p><strong className={styles.boldText}>Пользователь</strong> – покупатель, осуществляющий покупку товаров/услуг в Магазине Продавца.</p>
                    <p><strong className={styles.boldText}>Конфиденциальность персональных данных</strong> — обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</p>
                    <p><strong className={styles.boldText}>Cookies</strong> — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</p>
                </ul>

                <h1 className={styles.centeredHeader}>1. ОБЩИЕ ПОЛОЖЕНИЯ</h1>

                <ul className={styles.paragraph}>
                    <p>1.1. Совершение Пользователем покупки товаров/услуг в Магазине Продавца означает согласие с настоящей Политикой и условиями обработки персональных данных Пользователя.</p>
                    <p>1.2. В случае несогласия с условиями Политики Пользователь должен прекратить оформление покупки в Магазине продавца.</p>
                    <p>1.3. Оператор не проверяет достоверность персональных данных, предоставляемых Пользователем.</p>
                </ul>

                <h1 className={styles.centeredHeader}>2. ПРЕДМЕТ ПОЛИТИКИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
                <ul className={styles.paragraph}>
                    <p>2.1. Настоящая Политика устанавливает обязательства Оператора по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет Продавцу и Оператору при оформлении покупки товара/услуг в Магазине, а также персональные данные, которые представитель Продавца предоставляет Оператору.</p>
                    <p>2.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики, предоставляются Пользователем путём оформления покупки товаров/услуг в Магазине Продавца и включают в себя следующую информацию:</p>
                    <ul className={styles.paragraph}>
                        <p>2.2.1. фамилию, имя, отчество Пользователя;</p>
                        <p>2.2.2. контактный телефон Пользователя;</p>
                        <p>2.2.3. адрес места жительства Пользователя;</p>
                        <p>2.2.4. адрес электронной почты Пользователя.</p>
                    </ul>
                    <p>2.3. Персональные данные, разрешённые к обработке в рамках настоящей Политики, предоставляются представителем Продавца путём регистрации и заполнения контактных данных на Сервисе, заполнения формы обратной связи на Сервисе и включают в себя следующую информацию:</p>
                    <ul className={styles.paragraph}>
                        <p>2.3.1. фамилию, имя, отчество представителя Продавца;</p>
                        <p>2.3.2. контактный телефон представителя Продавца;</p>
                        <p>2.3.3. адрес электронной почты представителя Продавца.</p>
                    </ul>
                    <p>2.4. Сервис защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы («пиксель»):</p>
                    <ul className={styles.paragraph}>
                        <p>IP адрес;</p>
                        <p>информация из cookies;</p>
                        <p>информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);</p>
                        <p>время доступа;</p>
                        <p>адрес страницы, на которой расположен рекламный блок.</p>
                        <p>2.4.1. Отключение cookies может повлечь невозможность доступа к частям Сайта, требующим авторизации.</p>
                        <p>2.4.2. Сервис осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.</p>
                    </ul>
                </ul>

                <h1 className={styles.centeredHeader}>3. ПРИНЦИПЫ И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
                <ul className={styles.paragraph}>
                    <p>3.1 Принципы обработки персональных данных.</p>
                    <p>Обработка персональных данных у Оператора осуществляется на основе следующих принципов:</p>
                    <ul className={styles.paragraph}>
                        <li>законности и справедливой основы;</li>
                        <li>ограничения обработки персональных данных достижением конкретных, заранее определенных и законных целей;</li>
                        <li>недопущения обработки персональных данных, несовместимой с целями сбора персональных данных;</li>
                        <li>недопущения объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой;</li>
                        <li>обработки только тех персональных данных, которые отвечают целям их обработки;</li>
                        <li>соответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки;</li>
                        <li>недопущения обработки персональных данных, избыточных по отношению к заявленным целям их обработки;</li>
                        <li>обеспечения точности, достаточности и актуальности персональных данных по отношению к целям обработки персональных данных;</li>
                        <li>уничтожения либо обезличивания персональных данных по достижении целей их обработки или в случае утраты необходимости в достижении этих целей, при невозможности устранения Оператором допущенных нарушений персональных данных, если иное не предусмотрено федеральным законом.</li>
                    </ul>

                    <p>3.2 Условия обработки персональных данных</p>
                    <p>Обработка персональных данных осуществляется Оператором при соблюдении следующих условий:</p>
                    <ul className={styles.paragraph}>
                        <li>обработка персональных данных Пользователей осуществляется на основании Гражданского кодекса РФ, Конституции РФ, действующего законодательства РФ в области защиты персональных данных.</li>
                        <li>обработка персональных данных Сервисом осуществляется с соблюдением принципов и правил, предусмотренных Политикой и законодательством РФ.</li>
                    </ul>
                    <p>Обработка персональных данных допускается в следующих случаях:</p>
                    <ul className={styles.paragraph}>
                        <li>обработка персональных данных необходима для исполнения Оператором договора, стороной которого является Пользователь;</li>
                        <li>обработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов Пользователя, если получение согласия невозможно;</li>
                        <li>обработка персональных данных необходима для осуществления прав и законных интересов Оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы Пользователей;</li>
                        <li>обработка персональных данных осуществляется в целях продвижения товаров, работ, услуг на рынке путем осуществления прямых контактов с потенциальными потребителями помощью средств связи, а также в статистических или иных исследовательских целях, при условии обязательного обезличивания персональных данных.</li>
                    </ul>

                    <p>3.3. Цели обработки персональных данных:</p>
                    <ul className={styles.paragraph}>
                        <p>3.3.1. Идентификация Пользователя, оформившего покупку товара/услуг в Магазине для исполнения обязательств Продавцом перед Пользователем по договору розничной купли-продажи.</p>
                        <p>3.3.2. Предоставление Продавцу доступа к персонализированным ресурсам Сервиса.</p>
                        <p>3.3.3. Установление с Продавцом обратной связи, включая направление уведомлений, запросов, касающихся использования Магазина, оказания услуг, обработки запросов и заявок от Пользователя.</p>
                        <p>3.3.4. Определение места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</p>
                        <p>3.3.5. Подтверждение достоверности и полноты персональных данных, предоставленных Пользователем.</p>
                        <p>3.3.6. Обработка и получение платежей от Пользователя.</p>
                        <p>3.3.7. Предоставление Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием Магазина.</p>
                        <p>3.3.8. Предоставление Пользователю с его согласия специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Оператора.</p>
                        <p>3.3.9. Осуществление рекламной деятельности с согласия Пользователя.</p>
                        <p>3.3.10. Сбор статистических данных и формирование аналитических отчетов Оператором.</p>
                    </ul>

                    <p>3.1 Принципы обработки персональных данных.</p>
                    <p>Обработка персональных данных у Оператора осуществляется на основе следующих принципов:</p>
                    <ul className={styles.paragraph}>
                        <li>законности и справедливой основы;</li>
                        <li>ограничения обработки персональных данных достижением конкретных, заранее определенных и законных целей;</li>
                        <li>недопущения обработки персональных данных, несовместимой с целями сбора персональных данных;</li>
                        <li>недопущения объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой;</li>
                        <li>обработки только тех персональных данных, которые отвечают целям их обработки;</li>
                        <li>соответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки;</li>
                        <li>недопущения обработки персональных данных, избыточных по отношению к заявленным целям их обработки;</li>
                        <li>обеспечения точности, достаточности и актуальности персональных данных по отношению к целям обработки персональных данных;</li>
                        <li>уничтожения либо обезличивания персональных данных по достижении целей их обработки или в случае утраты необходимости в достижении этих целей, при невозможности устранения Оператором допущенных нарушений персональных данных, если иное не предусмотрено федеральным законом.</li>
                    </ul>

                    <p>3.2 Условия обработки персональных данных</p>
                    <p>Обработка персональных данных осуществляется Оператором при соблюдении следующих условий:</p>
                    <ul className={styles.paragraph}>
                        <li>обработка персональных данных Пользователей осуществляется на основании Гражданского кодекса РФ, Конституции РФ, действующего законодательства РФ в области защиты персональных данных.</li>
                        <li>обработка персональных данных Сервисом осуществляется с соблюдением принципов и правил, предусмотренных Политикой и законодательством РФ.</li>
                    </ul>
                    <p>Обработка персональных данных допускается в следующих случаях:</p>
                    <ul className={styles.paragraph}>
                        <li>обработка персональных данных необходима для исполнения Оператором договора, стороной которого является Пользователь;</li>
                        <li>обработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов Пользователя, если получение согласия невозможно;</li>
                        <li>обработка персональных данных необходима для осуществления прав и законных интересов Оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы Пользователей;</li>
                        <li>обработка персональных данных осуществляется в целях продвижения товаров, работ, услуг на рынке путем осуществления прямых контактов с потенциальными потребителями помощью средств связи, а также в статистических или иных исследовательских целях, при условии обязательного обезличивания персональных данных.</li>
                    </ul>

                    <p>3.3. Цели обработки персональных данных:</p>
                    <ul className={styles.paragraph}>
                        <p>3.3.1. Идентификация Пользователя, оформившего покупку товара/услуг в Магазине для исполнения обязательств Продавцом перед Пользователем по договору розничной купли-продажи.</p>
                        <p>3.3.2. Предоставление Пользователю доступа к персонализированным ресурсам Сервиса.</p>
                        <p>3.3.3. Установление с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Магазина, оказания услуг, обработки запросов и заявок от Пользователя.</p>
                        <p>3.3.4. Определение места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</p>
                        <p>3.3.5. Подтверждение достоверности и полноты персональных данных, предоставленных Пользователем.</p>
                        <p>3.3.6. Обработка и получение платежей от Пользователя.</p>
                        <p>3.3.7. Предоставление Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием Магазина.</p>
                        <p>3.3.8. Предоставление Пользователю с его согласия специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Оператора.</p>
                        <p>3.3.9. Осуществление рекламной деятельности с согласия Пользователя.</p>
                        <p>3.3.10. Сбор статистических данных и формирование аналитических отчетов Оператором.</p>
                        <p>3.3.11. Идентификация представителя Продавца, который осуществляет использование Сервиса.</p>
                    </ul>
                </ul>

                <h1 className={styles.centeredHeader}>4. ПРАВА СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
                <ul className={styles.paragraph}>
                    <p>4.1 Субъект персональных данных принимает решение о предоставлении его персональных данных и дает согласие на их обработку свободно, своей волей и в своем интересе. Согласие на обработку персональных данных может быть дано субъектом персональных данных или его представителем в любой позволяющей подтвердить факт его получения форме, если иное не установлено федеральным законом. Обязанность предоставить доказательство получения согласия субъекта персональных данных на обработку его персональных данных или доказательство наличия оснований, указанных в ФЗ-152, возлагается на Оператора.</p>
                    <p>4.2 Права субъектов персональных данных:</p>
                    <ul className={styles.paragraph}>
                        <p>4.2.1. Право на получение сведений об Операторе, о месте его нахождения, о наличии у Оператора персональных данных, относящихся к собственным персональным данным, а также на ознакомление с такими персональными данными, за исключением случаев, предусмотренных частью 8 статьи 14 Федерального закона «О персональных данных».</p>
                        <p>4.2.2. Право на получение от Оператора при личном обращении к нему либо при получении Оператором письменного запроса следующей информации, касающейся обработки его персональных данных, в том числе содержащей:</p>
                        <ul className={styles.paragraph}>
                            <li>подтверждение факта обработки персональных данных Оператором, а также цель такой обработки;</li>
                            <li>правовые основания и цели обработки персональных данных;</li>
                            <li>цели и применяемые Оператором способы обработки персональных данных;</li>
                            <li>наименование и место нахождения Оператора, сведения о лицах (за исключением работников оператора), которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора с Оператором или на основании Федерального закона;</li>
                            <li>обрабатываемые персональные данные, относящиеся к соответствующему субъекту персональных данных, источник их получения, если иной порядок предоставления таких данных не предусмотрен Федеральным законом;</li>
                            <li>сроки обработки персональных данных, в том числе сроки их хранения;</li>
                            <li>порядок осуществления субъектом персональных данных прав, предусмотренных Федеральным законом;</li>
                            <li>информацию об осуществленной или о предполагаемой трансграничной передаче данных;</li>
                            <li>наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных по поручению Оператора, если обработка поручена или будет поручена такому лицу;</li>
                            <li>иные сведения, предусмотренные Федеральным законом или другими федеральными законами;</li>
                            <li>требовать изменения, уточнения, уничтожения информации о самом себе;</li>
                            <li>обжаловать неправомерные действия или бездействие по обработке персональных данных и требовать соответствующей компенсации в суде;</li>
                            <li>на дополнение персональных данных оценочного характера заявлением, выражающим его собственную точку зрения;</li>
                            <li>определять представителей для защиты своих персональных данных;</li>
                            <li>требовать от Оператора уведомления обо всех произведенных в них изменениях или исключениях из них.</li>
                        </ul>
                        <p>4.2.3. Право обжаловать в уполномоченном органе по защите прав субъектов персональных данных или в судебном порядке действия или бездействие Оператора, если считает, что последний осуществляет обработку его персональных данных с нарушением требований Федерального закона «О персональных данных» или иным образом нарушает его права и свободы.</p>
                        <p>4.2.4. Право на защиту своих прав и законных интересов, в том числе на возмещение убытков и (или) компенсацию морального вреда в судебном порядке.</p>
                    </ul>

                </ul>

                <h1 className={styles.centeredHeader}>5. ОБЯЗАННОСТИ ОПЕРАТОРА</h1>
                <ul className={styles.paragraph}>
                    <p>5.1. По факту личного обращения либо при получении письменного запроса субъекта персональных данных или его представителя Оператор, при наличии оснований, обязан в течение 30 дней с даты обращения либо получения запроса субъекта персональных данных или его представителя предоставить сведения в объеме, установленном Федеральным законом. Такие сведения должны быть предоставлены субъекту персональных данных в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, если имеются законные основания для раскрытия таких персональных данных.</p>
                </ul>

                <h1 className={styles.centeredHeader}>6. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</h1>
                <ul className={styles.paragraph}>
                    <p>6.1. Обработка персональных данных осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</p>
                    <p>6.2. Персональные данные могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.</p>
                    <p>6.3. При утрате или разглашении персональных данных Оператор информирует Пользователя об утрате или разглашении персональных данных.</p>
                    <p>6.4. Оператор принимает необходимые организационные и технические меры для защиты персональной информации от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>
                    <p>6.5. Оператор совместно с Пользователем и Продавцом принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных.</p>
                </ul>

                <h1 className={styles.centeredHeader}>7. ОБЕСПЕЧЕНИЕ БЕЗОПАСНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
                <ul className={styles.paragraph}>
                    <p>7.1. Безопасность персональных данных, обрабатываемых Оператором, обеспечивается реализацией правовых, организационных и технических мер, необходимых для обеспечения требований федерального законодательства в области защиты персональных данных.</p>
                    <p>7.2. Для предотвращения несанкционированного доступа к персональным данным Оператором применяются следующие организационно-технические меры:</p>
                    <ul className={styles.paragraph}>
                        <li>назначение должностных лиц, ответственных за организацию обработки и защиты персональных данных;</li>
                        <li>ограничение состава лиц, имеющих доступ к персональным данным;</li>
                        <li>ознакомление субъектов с требованиями федерального законодательства и нормативных документов Оператора по обработке и защите персональных данных;</li>
                        <li>организация учета, хранения и обращения носителей информации;</li>
                        <li>определение угроз безопасности персональных данных при их обработке, формирование на их основе моделей угроз;</li>
                        <li>разработка на основе модели угроз системы защиты персональных данных;</li>
                        <li>проверка готовности и эффективности использования средств защиты информации;</li>
                        <li>разграничение доступа пользователей к информационным ресурсам и программно- аппаратным средствам обработки информации;</li>
                        <li>регистрация и учет действий пользователей информационных систем персональных данных;</li>
                        <li>использование антивирусных средств и средств восстановления системы защиты персональных данных;</li>
                        <li>применение в необходимых случаях средств межсетевого экранирования, обнаружения вторжений, анализа защищенности и средств криптографической защиты информации;</li>
                        <li>организация пропускного режима на территорию Оператора, охраны помещений с техническими средствами обработки персональных данных.</li>
                    </ul>
                </ul>

                <h1 className={styles.centeredHeader}>8. РАЗРЕШЕНИЕ СПОРОВ</h1>
                <ul className={styles.paragraph}>
                    <p>8.1. До обращения в суд с иском по спорам, возникающим из отношений между Сторонами в рамках настоящей Политики, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).</p>
                    <p>8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</p>
                    <p>8.3. При невозможности урегулирования разногласий, спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Российской Федерации.</p>
                    <p>8.4. К настоящей Политике и отношениям между Пользователем и Оператором применяется действующее законодательство Российской Федерации.</p>
                </ul>

                <h1 className={styles.centeredHeader}>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h1>
                <ul className={styles.paragraph}>
                    <p>9.1. Оператор вправе вносить изменения в настоящую Политику в одностороннем порядке без согласия Пользователя.</p>
                    <p>9.2. Новая Политика вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики.</p>
                    <p>9.3. Действующая Политика размещена по <Link to={'/privacy-policy'}>адресу</Link>.</p>
                    <p>9.1. Оператор вправе вносить изменения в настоящую Политику в одностороннем порядке без согласия Пользователя и Продавца.</p>
                    <p>9.2. Новая Политика вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики.</p>
                    <p>9.3. Действующая Политика размещена по адресу <Link to={'/privacy-policy'}>https://docs.thebotique.ru/privacy-policy</Link>.</p>

                </ul>

                <p><strong>Реквизиты Оператора:</strong></p>
                Индивидуальный предприниматель Кашапов Артем Ильясович
                ОГРНИП: 323631200088051
                ИНН: 631203635853
                Адрес: 443098, Россия, Самарская обл., г. Самара, пр-кт Карла Маркса, д. 472
                Р/с 40802810300004835673 в АО «Тинькофф Банк»
                К/с 30101810145250000974
                БИК 044525974

            </div>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
