import React, { useState, useEffect } from "react";
import styles from "./UpButton.module.css";

export default function UpButton({ footerRef }) {
    const [isVisible, setIsVisible] = useState(false);
    const [buttonBottom, setButtonBottom] = useState(40);

    const handleScroll = () => {
        setIsVisible(window.scrollY > 300);

        if (footerRef?.current) {
            const footerTop = footerRef.current.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            if (footerTop < windowHeight) {
                setButtonBottom(windowHeight - footerTop + 20);
            } else {
                setButtonBottom(40);
            }
        }
    };

    const handlerUP = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        isVisible && (
            <button
                className={styles.btn}
                onClick={handlerUP}
                style={{ bottom: `${buttonBottom}px` }}
                aria-label="Scroll to top"
            ></button>
        )
    );
}
