import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from './Card';
import './CardContainer.css';

const CardContainer = () => {
    const nav = useNavigate();
    const cardsData = [
        { url: '/license-agreement', title: 'Лицензионный договор', text: 'Документ определяет условия использования нашего программного обеспечения и права, предоставляемые пользователю. Пожалуйста, ознакомьтесь с договором перед использованием продукта.' },
        { url: '/terms-of-use', title: 'Правила использования сервиса', text: 'В документе уточняются условия пользования сервисом, обязанности пользователей и права администраторов. Пожалуйста, ознакомьтесь с правилами перед началом использования сервиса, чтобы соблюдать стандарты и создать безопасное и комфортное взаимодействие для всех участников.' },
        { url: '/return-policy', title: 'Правила возврата товаров', text: 'Основные правила, сроки и условия для возврата товаров, в том числе информация о необходимых документах, состоянии товара при возврате, а также процедурах возврата средств или обмена.' },
        { url: '/privacy-policy', title: 'Политика обработки персональных данных', text: 'Политика обработки персональных данных — это документ, который определяет, как мы собираем, используем и защищаем ваши персональные данные.' },
        { url: '/about-tariffs', title: 'Список тарифов и порядок совершения платежей', text: 'Документ определяет структуру и условия тарифной политики нашего Сервиса, а также устанавливает правила и порядок осуществления платежей пользователей. Целью данного документа является обеспечение прозрачности и взаимопонимания между Сервисом и пользователями в отношении финансовых аспектов использования предоставляемых услуг.' },
        { url: '/about-categories', title: 'Перечени доступных категорий и подкатегорий товаров', text: 'На данной странице представлен полный обзор доступных категорий и подкатегорий товаров. Ознакомьтесь с разнообразием товарных групп, включая электронику, одежду, обувь, товары для дома и сада, а также товары для спорта и отдыха. Каждая категория содержит подробное описание и перечень подкатегорий для вашего удобства выбора.' },
    ];

    return (
        <div className='main-container'>
            <div className="card-container">
                <span>Продавцам</span>
                <div className="card-list">
                    {cardsData.map((el, ind) => (
                        <Card key={ind} onClick={() => { nav(el.url) }} title={el.title} text={el.text} />
                    ))}
                </div>
                <span>Разработчикам</span>
                <div className="card-list">
                    <Card onClick={() => { nav('/about-api') }} title='API для управления товарами' text='В данном разделе документации описываются основные методы и принципы работы с товарами через внешнее API. API для управления товарами обеспечивает удобный способ автоматизации работы с товарами, повышает эффективность процессов управления продуктами и улучшает пользовательский опыт при работе с системой.' />
                </div>
            </div>
        </div>
    );
};

export default CardContainer;
