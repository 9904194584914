import React, { useEffect } from 'react';
import GetProductsList from './DocsAPISections/GetProductsList';
import NewProductDoc from './DocsAPISections/NewProductDoc';
import UpdateProductDoc from './DocsAPISections/UpdateProductDoc';
import styles from './DocumentPage.module.css';
import Footer from './components/Footer';
import Header from './components/Header';
import NavigationMenu from './components/NavigationMenu';
import GetProduct from './DocsAPISections/GetProduct';

const APIdocs = () => {

    useEffect(() => {
        document.title = "API для управления товарами на Сервисе «Botique»";
    }, []);

    return (
        <>
            <Header />
            <NavigationMenu />
            <div className={styles.licensePage}>
                <h1 className={styles.centeredHeader}>Документация API для управления товарами на Сервисе «Botique»</h1>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>Раздел "API для управления товарами" предоставляет доступ к программному интерфейсу нашего приложения, который позволяет автоматизировать процесс добавления товаров. С помощью этого API вы можете выпускать токены и добавлять новые товары в вашу систему.</p>
                <ul className={styles.paragraph}>
                    <li><strong className={styles.boldText}>Выпуск токенов API</strong>: доступ к этой функции становится возможным после оформления индивидуального тарифного плана. Токены API выдаются по запросу через службу поддержки нашего сервиса.</li>
                    <li><strong className={styles.boldText}>Добавление товаров</strong>: функционал для автоматического добавления новых товаров в систему с указанием основной информации о них, такой как название, описание, цена, фотографии и другие характеристики.</li>
                </ul>
                <div>
                    <NewProductDoc />
                    <hr />
                    <GetProduct />
                    <hr />
                    <UpdateProductDoc />
                    <hr />
                    <GetProductsList />
                    <hr />
                </div>
            </div >
            <Footer />
        </>
    );
};

export default APIdocs;
